<template>
    <div class="project-details-container" :class="{'schedule': routeName === 'schedule','tp-estimate': routeName === 'estimate-form', 'project-chat': routeName === 'chat', 'project-schedule': routeName === 'estimate-schedule', 'estimates': routeName === 'estimates'}" v-if="hasMainLayoutInitialized">
        <section class="project-details">
          <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </section>
    </div>
</template>
<script>

import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { mapGetters } from 'vuex';

import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  name: 'project-details',
  components: {
  },
  setup() {
    const routeName = computed(() => {
      return useRouter().currentRoute.value.name;
    });

    return {
      routeName,
    };
  },
  computed: {
    ...mapGetters(USERS_STORE, ['hasMainLayoutInitialized']),
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.project-details-container {
    height:100%;
    margin-left: .7rem;
    margin-right:20px;
    .project-details {
      height: 100%;
    }
}
.project-details-container.tp-estimate {
  .project-details {
    height: calc(100% - 3.3rem);
    // background: pink;
  }
}

@include media-md-max-width {
  .project-details-container {
    margin-left: 20px;
  }
}
@include media-sm-max-width {
  .project-details-container {
    // margin-top: 54px;
    // max-height: calc(100% - 125px);
    height: 100%;
    margin-top: 0;
  }
  .project-details-container.estimates {
    height: calc(100% - 2rem);
  }
}
@include media-xs-max-width {
  .project-details-container {
    max-height: calc(100% - 50px);
    margin: 0 10px;
  }
  .project-details-container.project-chat {
    // max-height: calc(100% - 104px);
    max-height: calc(100% - 0px);
    margin-top: 0;
    height: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .project-details-container.project-schedule {
    max-height: calc(100% - 15px);
  }
  .project-details-container.schedule {
    max-height: calc(100% - 2px);
  }
}
</style>
